exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessoires-js": () => import("./../../../src/pages/accessoires.js" /* webpackChunkName: "component---src-pages-accessoires-js" */),
  "component---src-pages-blog-antivirus-sur-telephone-utile-ou-non-js": () => import("./../../../src/pages/blog/antivirus-sur-telephone-utile-ou-non.js" /* webpackChunkName: "component---src-pages-blog-antivirus-sur-telephone-utile-ou-non-js" */),
  "component---src-pages-blog-comment-debloquer-son-telephone-google-icloud-js": () => import("./../../../src/pages/blog/comment-debloquer-son-telephone-google-icloud.js" /* webpackChunkName: "component---src-pages-blog-comment-debloquer-son-telephone-google-icloud-js" */),
  "component---src-pages-blog-comment-poser-un-verre-trempe-sur-son-telephone-js": () => import("./../../../src/pages/blog/comment-poser-un-verre-trempe-sur-son-telephone.js" /* webpackChunkName: "component---src-pages-blog-comment-poser-un-verre-trempe-sur-son-telephone-js" */),
  "component---src-pages-blog-comment-soft-reset-un-smartphone-js": () => import("./../../../src/pages/blog/comment-soft-reset-un-smartphone.js" /* webpackChunkName: "component---src-pages-blog-comment-soft-reset-un-smartphone-js" */),
  "component---src-pages-blog-deploiement-de-la-5-g-en-france-js": () => import("./../../../src/pages/blog/deploiement-de-la-5g-en-france.js" /* webpackChunkName: "component---src-pages-blog-deploiement-de-la-5-g-en-france-js" */),
  "component---src-pages-blog-ecran-compatible-vs-original-js": () => import("./../../../src/pages/blog/ecran-compatible-vs-original.js" /* webpackChunkName: "component---src-pages-blog-ecran-compatible-vs-original-js" */),
  "component---src-pages-blog-film-protection-resine-vs-verre-trempe-js": () => import("./../../../src/pages/blog/film-protection-resine-vs-verre-trempe.js" /* webpackChunkName: "component---src-pages-blog-film-protection-resine-vs-verre-trempe-js" */),
  "component---src-pages-blog-google-lens-on-test-le-scanner-intelligent-js": () => import("./../../../src/pages/blog/google-lens-on-test-le-scanner-intelligent.js" /* webpackChunkName: "component---src-pages-blog-google-lens-on-test-le-scanner-intelligent-js" */),
  "component---src-pages-blog-huawei-un-retour-vers-android-js": () => import("./../../../src/pages/blog/huawei-un-retour-vers-android.js" /* webpackChunkName: "component---src-pages-blog-huawei-un-retour-vers-android-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-la-puissance-des-jeux-video-smartphones-js": () => import("./../../../src/pages/blog/la-puissance-des-jeux-video-smartphones.js" /* webpackChunkName: "component---src-pages-blog-la-puissance-des-jeux-video-smartphones-js" */),
  "component---src-pages-blog-resoudre-probleme-reseau-iphone-js": () => import("./../../../src/pages/blog/resoudre-probleme-reseau-iphone.js" /* webpackChunkName: "component---src-pages-blog-resoudre-probleme-reseau-iphone-js" */),
  "component---src-pages-blog-retour-sur-la-keynote-2019-js": () => import("./../../../src/pages/blog/retour-sur-la-keynote-2019.js" /* webpackChunkName: "component---src-pages-blog-retour-sur-la-keynote-2019-js" */),
  "component---src-pages-blog-telephone-avec-ou-sans-engagement-js": () => import("./../../../src/pages/blog/telephone-avec-ou-sans-engagement.js" /* webpackChunkName: "component---src-pages-blog-telephone-avec-ou-sans-engagement-js" */),
  "component---src-pages-blog-telephone-lent-voici-nos-solutions-js": () => import("./../../../src/pages/blog/telephone-lent-voici-nos-solutions.js" /* webpackChunkName: "component---src-pages-blog-telephone-lent-voici-nos-solutions-js" */),
  "component---src-pages-blog-telephone-tombe-dans-leau-js": () => import("./../../../src/pages/blog/telephone-tombe-dans-leau.js" /* webpackChunkName: "component---src-pages-blog-telephone-tombe-dans-leau-js" */),
  "component---src-pages-blog-test-mario-kart-tour-js": () => import("./../../../src/pages/blog/test-mario-kart-tour.js" /* webpackChunkName: "component---src-pages-blog-test-mario-kart-tour-js" */),
  "component---src-pages-blog-verifier-la-reparabilite-d-un-smartphone-avant-achat-js": () => import("./../../../src/pages/blog/verifier-la-reparabilite-d-un-smartphone-avant-achat.js" /* webpackChunkName: "component---src-pages-blog-verifier-la-reparabilite-d-un-smartphone-avant-achat-js" */),
  "component---src-pages-cgv-js": () => import("./../../../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-maintenance-js": () => import("./../../../src/pages/en-maintenance.js" /* webpackChunkName: "component---src-pages-en-maintenance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nous-decouvrir-js": () => import("./../../../src/pages/nous-decouvrir.js" /* webpackChunkName: "component---src-pages-nous-decouvrir-js" */),
  "component---src-pages-reparation-js": () => import("./../../../src/pages/reparation.js" /* webpackChunkName: "component---src-pages-reparation-js" */),
  "component---src-pages-reprise-js": () => import("./../../../src/pages/reprise.js" /* webpackChunkName: "component---src-pages-reprise-js" */),
  "component---src-pages-rgpd-js": () => import("./../../../src/pages/rgpd.js" /* webpackChunkName: "component---src-pages-rgpd-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

